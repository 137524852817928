<template>
  <b-performance-table
    :data="rows"
    :custom-headers="headers"
    :loading="loading"
    :show-diff="dates.compareWith"
    :data-grouped-by="groupBy"
  />
</template>

<script>
import { get } from 'vuex-pathify';
import { checkUserHasAiAssistant } from '@/util/utility-functions';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import fetchWindowTypeDataMixin from '@/components/mixins/fetch-window-type-data-mixin';
import performanceDataMixin from '~/components/mixins/performance-data-mixin';

export default {
  name: 'DashDetailAudiencesPerformanceList',
  components: {
    BPerformanceTable: () =>
      import(
        /* webpackChunkName: "b-performance-table" */ '~/components/elements/b-performance-table.vue'
      ),
  },

  mixins: [performanceDataMixin, fetchWindowTypeDataMixin],

  data() {
    return {
      loading: false,
      groupBy: 'audience',
      userHasAiAssistant: false,
    };
  },
  computed: {
    headers() {
      return [{ name: 'Name', sortKey: 'name' }, this.getCommonHeader()];
    },
    performance: get('dashboard/performance@audience'),
    rows() {
      if (!this.performance) {
        return [];
      }

      const rows = this.performance.map((item) => {
        const rowItem = this.transformToRowItem(item);
        rowItem.name = item.name;
        return rowItem;
      });

      return this.sortRows(rows);
    },
    currentUser: get('common/currentUser'),
  },
  async mounted() {
    try {
      this.userHasAiAssistant = checkUserHasAiAssistant(this.currentUser);
      if (!this.userHasAiAssistant) {
        await this.loadPerformanceAudienceData(this.payload);
      } else if (EventBus.performanceAPILoadedOnAIEnabled === false) {
        this.loadAllPerformanceData(this.payload);
      }
    } catch (err) {
      console.error('error mounting DashDetailAudiencesPerformanceList ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },

  methods: {
    async loadPerformanceData(payload) {
      if (!this.userHasAiAssistant) {
        await this.loadPerformanceAudienceData(payload);
      } else {
        this.loadAllPerformanceData(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-bg.zero {
  margin-top: 0 !important;
  margin-left: -10px !important;
}
</style>
